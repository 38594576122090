<template>
    <div class="apply_box">
        <div class="title">
            试用处理中
            <el-button @click="onStop()">中止处理</el-button>
        </div>
        <div class="info">
            <el-form
                ref="form"
                label-position="left"
                size="small"
                label-width="105px"
            >
                <el-form-item label="报备品牌" style="margin-left: -10px">
                    <span class="brand">腾讯企业邮箱</span>
                </el-form-item>
                <el-form-item label="报备名称" style="margin-left: -10px">
                    <span class="brand">{{
                        tableRow.reportName || '- -'
                    }}</span>
                    <el-button
                        type="text"
                        style="color: #2370eb; font-size: 16px; padding: 6px 0"
                        @click.stop="onCopy(tableRow.reportName)"
                        v-if="tableRow.reportName"
                        class="iconfont icon-fuzhi1"
                    ></el-button>
                </el-form-item>
                <el-form-item
                    :label="tableRow.nicheCategory == 1 ? '客户' : '报备渠道'"
                    style="margin-left: -10px"
                >
                    <span class="brand">{{
                        tableRow.nicheCategory == 1
                            ? tableRow.companyName || '- -'
                            : tableRow.channelManageName || '- -'
                    }}</span>
                </el-form-item>
                <el-form-item label="报备库" style="margin-left: -10px">
                    <span class="brand">{{ tableRow.libraryName }}</span>
                </el-form-item>
                <el-form-item label="邮箱版本" style="margin-left: -10px">
                    <span class="brand">{{
                        tableRow.emailVersionType == 2
                            ? '新版'
                            : tableRow.emailVersionType == 3
                            ? '旧版'
                            : '- -'
                    }}</span>
                </el-form-item>
                <el-form-item
                    :label="
                        tableRow.emailVersionType == 2
                            ? '企业ID'
                            : tableRow.emailVersionType == 3
                            ? '管理员账号'
                            : '- -'
                    "
                    style="margin-left: -10px"
                >
                    <span class="brand">{{
                        tableRow.emailVersionTypeName || '- -'
                    }}</span>
                </el-form-item>
                <el-form-item label="用户数" style="margin-left: -10px">
                    <span class="brand">{{
                        tableRow.trialNumber || '- -'
                    }}</span>
                </el-form-item>
                <el-form-item label="试用天数" style="margin-left: -10px">
                    <span class="brand"
                        >{{ tableRow.trialDays || '- -' }}天</span
                    >
                </el-form-item>
                <el-form-item label="备注" style="margin-left: -10px">
                    <span class="brand">{{
                        tableRow.trialRemark || '- -'
                    }}</span>
                </el-form-item>
            </el-form>
        </div>
        <div class="audit">
            <el-form
                ref="form"
                label-position="left"
                size="small"
                label-width="105px"
            >
                <el-form-item
                    label="处理结果"
                    required
                    style="margin-left: -10px"
                >
                    <el-radio-group v-model="form.auditStatus">
                        <el-radio :label="1">成功</el-radio>
                        <el-radio :label="2">失败</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item
                    label="回收时间"
                    required
                    style="margin-left: -10px"
                    v-if="form.auditStatus != 2"
                >
                    <el-date-picker
                        v-model="form.expireTime"
                        size="small"
                        type="date"
                        placeholder="选择日期"
                        value-format="yyyy-MM-dd HH:mm:ss"
                        style="width: 240px"
                        @change="expireTime()"
                        :picker-options="expireTimeOption"
                    >
                    </el-date-picker>
                    <el-radio-group
                        style="display: block; margin-top: 8px"
                        v-model="expireTimeBtn"
                        size="small"
                    >
                        <el-radio-button
                            label="30天"
                            @click.native.prevent="clickTime('30天')"
                        ></el-radio-button>
                        <el-radio-button
                            label="60天"
                            @click.native.prevent="clickTime('60天')"
                        ></el-radio-button>
                        <el-radio-button
                            label="90天"
                            @click.native.prevent="clickTime('90天')"
                        ></el-radio-button>
                    </el-radio-group>
                </el-form-item>

                <el-form-item label="处理备注" style="margin-left: -10px">
                    <el-input
                        type="textarea"
                        v-model="form.auditRemark"
                        size="small"
                        placeholder="请输入(成功/失败理由)"
                        style="width: 240px"
                        rows="6"
                    ></el-input>
                </el-form-item>
            </el-form>
        </div>
        <div class="bottom-but">
            <el-button
                round
                type="primary"
                size="mini"
                style="
                    background: none;
                    border-color: #d5d6df;
                    color: #666666;
                    margin-left: 270px;
                "
                @click="onClose"
                >取 消</el-button
            >

            <el-button
                round
                type="primary"
                style="background: #2370eb; border-color: #2370eb; color: #fff"
                size="mini"
                @click="onSubmit"
                >保 存</el-button
            >
        </div>
    </div>
</template>

<script>
import { reportAudit, reportUpdate, reportGet } from '@/api/report/report';
export default {
    data() {
        return {
            tableRow: {},
            form: {
                auditStatus: '',
                auditRemark: '',
                expireTime: '',
            },
            expireTimeOption: {},
            expireTimeBtn: '',
        };
    },
    methods: {
        getData(row) {
            let data = {
                param: {
                    id: row.id,
                },
            };
            reportGet(data).then((res) => {
                this.tableRow = res.data;
                this.expireTimeOption = {
                    disabledDate(date) {
                        let time = new Date(res.data.reportExpireTime);
                        // disabledDate 文档上：设置禁用状态，参数为当前日期，要求返回 Boolean
                        return date.getTime() > time.getTime();
                    },
                };

                let date = new Date();
                this.expireTimeBtn = '30天';
                this.form.expireTime =
                    this.$searchTime.getNextDate(date, -30) + ' 00:00:00';
            });
        },
        clickTime(e) {
            e === this.expireTimeBtn
                ? (this.expireTimeBtn = '')
                : (this.expireTimeBtn = e);
            let date = new Date();
            if (e == this.expireTimeBtn && e == '30天') {
                this.form.expireTime =
                    this.$searchTime.getNextDate(date, -30) + ' 00:00:00';
            } else if (e == this.expireTimeBtn && e == '60天') {
                this.form.expireTime =
                    this.$searchTime.getNextDate(date, -60) + ' 00:00:00';
            } else if (e == this.expireTimeBtn && e == '90天') {
                this.form.expireTime =
                    this.$searchTime.getNextDate(date, -90) + ' 00:00:00';
            } else {
                this.form.expireTime = '';
            }
        },
        expireTime() {
            this.expireTimeBtn = '';
        },
        onSubmit() {
            let dateBegin = new Date(this.tableRow.reportExpireTime);
            let date = new Date(this.form.expireTime);

            let result = date.getTime() - dateBegin.getTime();
            let joinTime = Math.floor(result / (24 * 3600 * 1000));

            if (!this.form.auditStatus) {
                return this.$message.error('请选择审核结果');
            }

            if (this.form.auditStatus == 1) {
                if (!this.form.expireTime) {
                    return this.$message.error('请选择回收时间');
                }
                if (joinTime > 0) {
                    return this.$message.error('回收时间不能超过报备到期时间');
                }
            }
            // if (!this.form.auditRemark) {
            //     return this.$message.error('请输入审核备注');
            // }
            let data = {
                param: {
                    auditStatus: this.form.auditStatus,
                    auditRemark: this.form.auditRemark,
                },
            };
            if (this.form.auditStatus == 1) {
                data.param.expireTime = this.form.expireTime;
            }
            data.param.id = this.tableRow.nicheAuditBO.id;
            reportAudit(data).then((res) => {
                if (res.code == 200) {
                    this.$message.success('操作成功');
                    for (let i in this.form) {
                        this.form[i] = '';
                    }
                    this.expireTimeBtn = '';
                    this.onClose();
                }
            });
        },
        // 复制
        onCopy(item) {
            let url = item;
            let oInput = document.createElement('input');
            oInput.value = url;
            document.body.appendChild(oInput);
            oInput.select(); // 选择对象;
            document.execCommand('Copy'); // 执行浏览器复制命令
            this.$message({
                message: '复制成功',
                type: 'success',
            });
            oInput.remove();
        },
        onClose() {
            for (let i in this.form) {
                this.form[i] = '';
            }
            this.expireTimeBtn = '';
            this.$emit('close');
        },
        onStop() {
            if (
                this.tableRow.nicheAuditBO.auditAdminId !=
                sessionStorage.getItem('adminId')
            ) {
                return this.$message.error('该条数据无法处理');
            }
            let data = {
                param: {
                    id: this.tableRow.id,
                    trialStatus: Number(this.tableRow.trialStatus) - 1,
                },
            };
            reportUpdate(data).then((res) => {
                if (res.code == 200) {
                    this.$message.success('操作成功');
                    this.onClose();
                }
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.apply_box {
    width: 100%;
    .title {
        height: 60px;
        line-height: 60px;
        padding-left: 40px;
        padding-right: 25px;
        font-size: 14px;
        font-weight: 600;
        border-bottom: 1px solid #e5e5e5;
        button {
            float: right;
            width: 80px;
            height: 32px;
            background: #2370eb;
            border-radius: 2px;
            line-height: 28px;
            padding: 0;
            color: #fff;
            margin-top: 15px;
        }
    }
    .info {
        border-bottom: 1px dashed #e5e5e5;
        padding-bottom: 20px;
    }
    .audit {
        padding-bottom: 50px;
    }
    .brand {
        display: inline-block;
        width: 240px;
        box-sizing: border-box;
        padding-left: 10px;
        color: #333333;
        font-size: 14px;
        border-radius: 2px;
    }
    .el-form {
        margin-left: 50px;
        margin-top: 20px;
    }
}
.info {
    /deep/ .el-form-item {
        margin-bottom: 0px !important;
    }
}
.bottom-but {
    width: 480px;
    line-height: 48px;
    text-align: right;
    background: #fff;
    border-top: 1px solid #dee0e3;
    z-index: 1000;
    bottom: 0;
    position: fixed;
    button {
        width: 90px;
        z-index: 1000;
    }
}
/deep/ .el-textarea__inner {
    resize: none;
}
/deep/ .el-radio-button--mini .el-radio-button__inner {
    padding: 5px 10px !important;
}
.faid {
    /deep/ .el-radio-button__orig-radio:checked + .el-radio-button__inner {
        color: #d0021b !important;
        border-color: #d0021b !important;
    }
}
</style>
