<template>
    <div class="apply_box">
        <div class="title">
            添加域名处理中
            <el-button @click="onStop">中止处理</el-button>
        </div>
        <div class="info">
            <el-form
                ref="form"
                label-position="left"
                size="small"
                label-width="90px"
            >
                <el-form-item label="报备品牌" style="margin-left: -10px">
                    <span class="brand">网易企业邮箱</span>
                </el-form-item>
                <el-form-item label="报备名称" style="margin-left: -10px">
                    <span class="brand">{{
                        tableRow.reportName || '- -'
                    }}</span>
                    <el-button
                        type="text"
                        style="color: #2370eb; font-size: 16px; padding: 6px 0"
                        @click.stop="onCopy"
                        v-if="tableRow.reportName"
                        class="iconfont icon-fuzhi1"
                    ></el-button>
                </el-form-item>
                <div v-for="(item, index) in tableRow.nicheDomainBOList">
                    <el-form-item
                        label="域名"
                        v-if="item.auditStatus == 1 || item.auditStatus == 2"
                        style="margin-left: -10px"
                    >
                        <span class="brand">{{ item.domain || '- -' }}</span>
                        <span class="success" v-if="item.auditStatus == 1">
                            <i class="el-icon-success"></i>
                            成功
                        </span>
                        <span class="faid2" v-if="item.auditStatus == 2">
                            <i class="el-icon-warning"></i>
                            失败
                        </span>
                    </el-form-item>
                    <el-form-item
                        v-if="item.auditStatus == 1 || item.auditStatus == 2"
                        style="margin-left: -10px"
                    >
                        <span class="brand"
                            >用户数:{{ item.domain || '- -' }}</span
                        >
                        <!-- <span class="success" v-if="item.auditStatus == 1">
                            <i class="el-icon-success"></i>
                            成功
                        </span>
                        <span class="faid2" v-if="item.auditStatus == 2">
                            <i class="el-icon-warning"></i>
                            失败
                        </span> -->
                    </el-form-item>
                </div>
                <div v-for="(item, index) in tableRow.nicheDomainBOList">
                    <el-form-item
                        label="添加域名"
                        v-if="item.auditStatus != 1 && item.auditStatus != 2"
                        style="margin-left: -10px"
                    >
                        <span class="brand">{{ item.domain || '- -' }}</span>

                        <el-button
                            type="text"
                            style="color: #2370eb; font-size: 16px; padding: 0"
                            @click.stop="onCopy(item.domain)"
                            v-if="item.domain"
                            class="iconfont icon-fuzhi1"
                        ></el-button>
                        <el-radio-group
                            v-model="item.auditStatus"
                            size="mini"
                            @change="
                                (val) => {
                                    auditDomain(val, item);
                                }
                            "
                            style="margin-top: -8px; margin-left: 5px"
                        >
                            <el-radio-button label="成功"></el-radio-button>
                            <el-radio-button
                                label="失败"
                                class="faid"
                            ></el-radio-button>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item
                        v-if="item.auditStatus != 1 && item.auditStatus != 2"
                        style="margin-left: -10px"
                    >
                        <span class="brand"
                            >用户数:{{ item.domain || '- -' }}</span
                        >
                    </el-form-item>
                </div>
            </el-form>
        </div>
        <div class="audit">
            <el-form
                ref="form"
                label-position="left"
                size="small"
                label-width="90px"
            >
                <el-form-item
                    label="处理结果"
                    required
                    style="margin-left: -10px"
                >
                    <el-radio-group v-model="form.auditStatus">
                        <el-radio :label="1">成功</el-radio>
                        <el-radio :label="2">失败</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="处理备注" style="margin-left: -10px">
                    <el-input
                        type="textarea"
                        v-model="form.auditRemark"
                        size="small"
                        placeholder="请输入(成功/失败理由)"
                        style="width: 240px"
                        rows="6"
                    ></el-input>
                </el-form-item>
            </el-form>
        </div>
        <div class="bottom-but">
            <el-button
                round
                type="primary"
                size="mini"
                style="
                    background: none;
                    border-color: #d5d6df;
                    color: #666666;
                    margin-left: 270px;
                "
                @click="onClose"
                >取 消</el-button
            >

            <el-button
                round
                type="primary"
                style="background: #2370eb; border-color: #2370eb; color: #fff"
                size="mini"
                @click="onSubmit"
                >保 存</el-button
            >
        </div>
    </div>
</template>

<script>
import { reportAudit, reportUpdate } from '@/api/report/report';
export default {
    name: '',
    props: {},
    data() {
        return {
            tableRow: {},
            form: {
                auditStatus: '',
                auditRemark: '',
            },
            followDate: '',
            followTime: '',
            radio3: '失败',
            nicheDomainDOList: [],
        };
    },
    components: {},
    created() {},
    methods: {
        getData(row) {
            this.tableRow = row;
        },
        auditDomain(val, item) {
            if (val == '成功') {
                let index = this.nicheDomainDOList.findIndex((itm) => {
                    return itm.id == item.id;
                });
                if (index == -1) {
                    this.nicheDomainDOList.push({
                        id: item.id,
                        auditStatus: 1,
                    });
                } else {
                    this.nicheDomainDOList[index].id = item.id;
                    this.nicheDomainDOList[index].auditStatus = 1;
                }
            } else if (val == '失败') {
                let index = this.nicheDomainDOList.findIndex((itm) => {
                    return itm.id == item.id;
                });
                if (index == -1) {
                    this.nicheDomainDOList.push({
                        id: item.id,
                        auditStatus: 2,
                    });
                } else {
                    this.nicheDomainDOList[index].id = item.id;
                    this.nicheDomainDOList[index].auditStatus = 2;
                }
            }
        },
        // 复制
        onCopy(item) {
            let url = item;
            let oInput = document.createElement('input');
            oInput.value = url;
            document.body.appendChild(oInput);
            oInput.select(); // 选择对象;
            document.execCommand('Copy'); // 执行浏览器复制命令
            this.$message({
                message: '复制成功',
                type: 'success',
            });
            oInput.remove();
        },
        onSubmit() {
            if (!this.form.auditStatus) {
                return this.$message.error('请选择审核结果');
            }
            // else if (!this.form.auditRemark) {
            //     return this.$message.error('请输入审核备注');
            // }
            else if (this.nicheDomainDOList.length == 0) {
                return this.$message.error('请先审核域名');
            } else {
                if (
                    !this.nicheDomainDOList.some((item) => {
                        return item.auditStatus == 1;
                    }) &&
                    this.form.auditStatus == 1
                ) {
                    return this.$message.error(
                        '报备成功至少需要有1个域名报备成功'
                    );
                }
                let data = {
                    param: this.form,
                };
                data.param.id = this.tableRow.nicheAuditBO.id;
                data.param.nicheDomainDOList = this.nicheDomainDOList;
                reportAudit(data).then((res) => {
                    if (res.code == 200) {
                        this.$message.success('操作成功');
                        for (let i in this.form) {
                            this.form[i] = '';
                        }
                        this.onClose();
                    }
                });
            }
        },
        onStop() {
            if (
                this.tableRow.nicheAuditBO.auditAdminId !=
                    sessionStorage.getItem('adminId') &&
                this.tableRow.nicheAuditBO.auditAdminId != 0
            ) {
                return this.$message.error('该条数据无法处理');
            }
            let data = {
                param: {
                    id: this.tableRow.id,
                    reportStatus: Number(this.tableRow.reportStatus) - 1,
                },
            };
            reportUpdate(data).then((res) => {
                if (res.code == 200) {
                    this.$message.success('操作成功');

                    this.expireTimeBtn = '';
                    this.onClose();
                }
            });
        },
        onClose() {
            for (let i in this.form) {
                this.form[i] = '';
            }
            this.$emit('close');
        },
    },
};
</script>

<style lang="scss" scoped>
.apply_box {
    width: 100%;
    .title {
        height: 60px;
        line-height: 60px;
        padding-left: 40px;
        padding-right: 25px;
        font-size: 14px;
        font-weight: 600;
        border-bottom: 1px solid #e5e5e5;
        button {
            float: right;
            width: 80px;
            height: 32px;
            background: #2370eb;
            border-radius: 2px;
            line-height: 28px;
            padding: 0;
            color: #fff;
            margin-top: 15px;
        }
    }
    .info {
        border-bottom: 1px dashed #e5e5e5;
        padding-bottom: 20px;
    }
    .audit {
        padding-bottom: 50px;
    }
    .brand {
        display: inline-block;
        width: 240px;
        box-sizing: border-box;
        padding-left: 10px;
        color: #333333;
        font-size: 14px;
        border-radius: 2px;
    }
    .el-form {
        margin-left: 50px;
        margin-top: 20px;
    }
}
.info {
    /deep/ .el-form-item {
        margin-bottom: 0px !important;
    }
}
.bottom-but {
    width: 480px;
    line-height: 48px;
    text-align: right;
    background: #fff;
    border-top: 1px solid #dee0e3;
    z-index: 1000;
    bottom: 0;
    position: fixed;
    button {
        width: 90px;
        z-index: 1000;
    }
}
/deep/ .el-textarea__inner {
    resize: none;
}
/deep/ .el-radio-button--mini .el-radio-button__inner {
    padding: 5px 10px !important;
}
.faid {
    /deep/ .el-radio-button__orig-radio:checked + .el-radio-button__inner {
        color: #d0021b !important;
        border-color: #d0021b !important;
    }
}
.success {
    width: 60px;
    height: 20px;
    border-radius: 3px;
    display: inline-block;
    margin-left: 23px;
    line-height: 20px;
    font-size: 12px;
    text-align: center;
    border: 1px solid #2370eb;
    i {
        color: #2370eb;
    }
}
.faid2 {
    width: 60px;
    height: 20px;
    margin-left: 23px;
    border-radius: 3px;
    line-height: 20px;
    font-size: 12px;
    display: inline-block;
    text-align: center;
    border: 1px solid #d0021b;
    i {
        color: #d0021b;
    }
}
</style>
