<template>
    <div class="apply_box">
        <div class="title">
            报备处理中
            <el-button @click="onStop">中止处理</el-button>
        </div>
        <div class="info">
            <el-form
                ref="form"
                label-position="left"
                size="small"
                label-width="100px"
            >
                <el-form-item label="报备品牌" style="margin-left: -10px">
                    <span class="brand">网易企业邮箱</span>
                </el-form-item>
                <el-form-item label="报备名称" style="margin-left: -10px">
                    <span class="brand">{{
                        tableRow.reportName || '- -'
                    }}</span>
                    <el-button
                        type="text"
                        style="color: #2370eb; font-size: 16px; padding: 6px 0"
                        @click.stop="onCopy"
                        v-if="tableRow.reportName"
                        class="iconfont icon-fuzhi1"
                    ></el-button>
                </el-form-item>
                <div
                    v-for="(item, index) in tableRow.nicheDomainBOList"
                    :key="item.id"
                >
                    <el-form-item
                        :label="
                            item.auditStatus == 1 || item.auditStatus == 2
                                ? '已审核域名' + (index + 1)
                                : '域名' + (index + 1)
                        "
                        style="margin-left: -10px"
                    >
                        <span class="brand">{{ item.domain || '- -' }}</span>
                        <el-button
                            type="text"
                            style="color: #2370eb; font-size: 16px; padding: 0"
                            @click.stop="onCopy(item.domain)"
                            v-if="item.domain"
                            class="iconfont icon-fuzhi1"
                        ></el-button>
                        <el-radio-group
                            v-if="item.domain"
                            v-model="item.auditStatus"
                            size="mini"
                            style="margin-top: -8px; margin-left: 5px"
                            @change="
                                (val) => {
                                    auditDomain(val, item, index);
                                }
                            "
                        >
                            <el-radio-button :label="1" class="success"
                                >成功</el-radio-button
                            >
                            <el-radio-button :label="2" class="faid"
                                >失败</el-radio-button
                            >
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item style="margin-left: -10px">
                        <span class="brand">{{
                            item.number ? '用户数：' + item.number : '- -'
                        }}</span>
                    </el-form-item>
                    <el-form-item style="margin-left: -10px">
                        <span class="brand">{{
                            item.days ? '报备周期：' + item.days + '天' : '- -'
                        }}</span>
                    </el-form-item>
                </div>
                <el-form-item label="所在地" style="margin-left: -10px">
                    <span class="brand">{{
                        $address.address(tableRow.provinceId, tableRow.cityId)
                    }}</span>
                </el-form-item>
                <el-form-item label="报备理由" style="margin-left: -10px">
                    <span class="brand">{{
                        tableRow.reportReason || '- -'
                    }}</span>
                </el-form-item>
                <el-form-item label="联系人" style="margin-left: -10px">
                    <span class="brand">{{
                        tableRow.contactName || '- -'
                    }}</span>
                    <el-button
                        type="text"
                        style="color: #2370eb; font-size: 16px; padding: 6px 0"
                        @click.stop="onCopy(tableRow.contactName)"
                        v-if="tableRow.contactName"
                        class="iconfont icon-fuzhi1"
                    ></el-button>
                </el-form-item>
                <el-form-item label="联系手机" style="margin-left: -10px">
                    <span class="brand">{{
                        tableRow.contactPhone || '- -'
                    }}</span>
                    <el-button
                        type="text"
                        style="color: #2370eb; font-size: 16px; padding: 6px 0"
                        @click.stop="onCopy(tableRow.contactPhone)"
                        v-if="tableRow.contactPhone"
                        class="iconfont icon-fuzhi1"
                    ></el-button>
                </el-form-item>

                <el-form-item label="预计年限" style="margin-left: -10px">
                    <span class="brand">{{
                        tableRow.estimateTerm
                            ? $MailStatus.echoEstimateTerm(
                                  tableRow.estimateTerm
                              ) + '年'
                            : '- -'
                    }}</span>
                </el-form-item>
                <el-form-item label="备注" style="margin-left: -10px">
                    <span class="brand">{{
                        tableRow.reportRemark || '- -'
                    }}</span>
                </el-form-item>
            </el-form>
        </div>
        <div class="audit">
            <el-form
                ref="form"
                label-position="left"
                size="small"
                label-width="90px"
            >
                <el-form-item
                    label="处理结果"
                    required
                    style="margin-left: -10px"
                >
                    <el-radio-group v-model="form.auditStatus">
                        <el-radio :label="1">成功</el-radio>
                        <el-radio :label="2">失败</el-radio>
                    </el-radio-group>
                </el-form-item>
                <!-- <el-form-item
                    label="回收时间"
                    required
                    v-show="!form.auditStatus || form.auditStatus == 1"
                    style="margin-left: -10px"
                >
                    <el-date-picker
                        size="small"
                        v-model="form.expireTime"
                        type="date"
                        value-format="yyyy-MM-dd HH:mm:ss"
                        style="width: 240px"
                        @change="expireTime"
                    >
                    </el-date-picker>
                    <el-radio-group
                        style="display: block; margin-top: 8px"
                        v-model="followTime"
                        size="small"
                    >
                        <el-radio-button label="30天"></el-radio-button>
                        <el-radio-button label="60天"></el-radio-button>
                        <el-radio-button label="90天"></el-radio-button>
                    </el-radio-group>
                </el-form-item> -->
                <el-form-item
                    v-show="!form.auditStatus || form.auditStatus == 1"
                    label="报备库"
                    required
                    style="margin-left: -10px"
                >
                    <el-select
                        v-model="form.libraryCategory"
                        style="width: 240px"
                        placeholder="请选择报备库"
                        @change="changeLibraryCategory"
                    >
                        <el-option
                            v-for="item in accountList"
                            :key="item.id"
                            :label="item.accountName"
                            :value="item.id"
                        >
                        </el-option>
                    </el-select>
                    <p
                        style="color: #999; font-size: 12px"
                        v-if="form.libraryCategory"
                    >
                        <i
                            class="el-icon-warning-outline"
                            style="color: #f5a623"
                        ></i>
                        剩余报备名额：{{ surplus }}
                    </p>
                </el-form-item>
                <el-form-item label="处理备注" style="margin-left: -10px">
                    <el-input
                        type="textarea"
                        v-model="form.auditRemark"
                        size="small"
                        placeholder="请输入(成功/失败理由)"
                        style="width: 240px"
                        rows="6"
                    ></el-input>
                </el-form-item>
            </el-form>
        </div>
        <div class="bottom-but">
            <el-button
                round
                type="primary"
                size="mini"
                style="
                    background: none;
                    border-color: #d5d6df;
                    color: #666666;
                    margin-left: 270px;
                "
                @click="onClose"
                >取 消</el-button
            >

            <el-button
                round
                type="primary"
                style="background: #2370eb; border-color: #2370eb; color: #fff"
                size="mini"
                @click="onSubmit"
                >保 存</el-button
            >
        </div>
    </div>
</template>

<script>
import { reportAudit, reportUpdate, accountList } from '@/api/report/report';
export default {
    name: '',
    props: {},
    data() {
        return {
            tableRow: {},
            form: {
                auditStatus: '',
                libraryCategory: '',
                auditRemark: '',
            },
            followTime: '',
            radio3: '失败',
            nicheDomainDOList: [],
            accountList: [],
            surplus: '',
        };
    },
    components: {},
    created() {},
    methods: {
        getData(data) {
            console.log(data);
            data.nicheDomainBOList.forEach((item) => {
                if (item.number >= 5 && item.number <= 49) {
                    item.days = 30;
                } else if (item.number >= 50 && item.number <= 199) {
                    item.days = 60;
                } else if (item.number >= 200) {
                    item.days = 90;
                }
            });
            this.tableRow = data;
            // if (data.reportNumber >= 5 && data.reportNumber <= 49) {
            //     this.followTime = '30天';
            //     this.form.expireTime =
            //         this.$searchTime.getNextDate(new Date(), -31) + ' 00:00:00';
            // } else if (data.reportNumber >= 50 && data.reportNumber <= 199) {
            //     this.followTime = '60天';
            //     this.form.expireTime =
            //         this.$searchTime.getNextDate(new Date(), -61) + ' 00:00:00';
            // } else if (data.reportNumber >= 200) {
            //     this.followTime = '90天';
            //     this.form.expireTime =
            //         this.$searchTime.getNextDate(new Date(), -91) + ' 00:00:00';
            // }

            this.getaccountList();
            data.nicheDomainBOList.forEach((item) => {
                if (item.auditStatus == 1 || item.auditStatus == 2) {
                    this.nicheDomainDOList.push({
                        id: item.id,
                        auditStatus: item.auditStatus,
                        days,
                    });
                }
            });
            // this.auditDomain()
        },
        getaccountList() {
            let data = {
                param: {
                    productType: 4,
                },
                pageNum: 0,
                pageSize: 0,
            };

            accountList(data).then((res) => {
                if (res.code == 200) {
                    this.accountList = res.data.list;
                }
            });
        },
        changeLibraryCategory(val) {
            if (val) {
                let obj = this.accountList.find((item) => {
                    return item.id == val;
                });
                this.surplus =
                    Number(obj.maxReportNumber) - Number(obj.consumedNumber);
            }
        },
        clickFollow(e) {
            e === this.followTime
                ? (this.followTime = '')
                : (this.followTime = e);
            if (e == this.followTime && e == '30天') {
                this.form.expireTime =
                    this.$searchTime.getNextDate(new Date(), -31) + ' 00:00:00';
            } else if (e == this.followTime && e == '60天') {
                this.form.expireTime =
                    this.$searchTime.getNextDate(new Date(), -61) + ' 00:00:00';
            } else if (e == this.followTime && e == '90天') {
                this.form.expireTime =
                    this.$searchTime.getNextDate(new Date(), -91) + ' 00:00:00';
            } else {
                this.form.expireTime = '';
            }
        },
        auditDomain(val, item) {
            var expireTime = 0;
            if (item.number >= 5 && item.number <= 49) {
                // days = 30;
                expireTime =
                    this.$searchTime.getNextDate(new Date(), -31) + ' 00:00:00';
            } else if (item.number >= 50 && item.number <= 199) {
                expireTime =
                    this.$searchTime.getNextDate(new Date(), -61) + ' 00:00:00';
            } else if (item.number >= 200) {
                expireTime =
                    this.$searchTime.getNextDate(new Date(), -91) + ' 00:00:00';
            }
            if (val == 1) {
                let index = this.nicheDomainDOList.findIndex((itm) => {
                    return itm.id == item.id;
                });
                if (index == -1) {
                    this.nicheDomainDOList.push({
                        id: item.id,
                        auditStatus: 1,
                        expireTime,
                    });
                } else {
                    this.nicheDomainDOList[index].id = item.id;
                    this.nicheDomainDOList[index].auditStatus = 1;
                    this.nicheDomainDOList[index].expireTime = expireTime;
                }
            } else if (val == 2) {
                let index = this.nicheDomainDOList.findIndex((itm) => {
                    return itm.id == item.id;
                });
                if (index == -1) {
                    this.nicheDomainDOList.push({
                        id: item.id,
                        auditStatus: 2,
                        expireTime,
                    });
                } else {
                    this.nicheDomainDOList[index].id = item.id;
                    this.nicheDomainDOList[index].auditStatus = 2;
                    this.nicheDomainDOList[index].expireTime = expireTime;
                }
            }
        },
        expireTime() {
            this.followTime = '';
        },
        // 复制
        onCopy(item) {
            let url = item;
            let oInput = document.createElement('input');
            oInput.value = url;
            document.body.appendChild(oInput);
            oInput.select(); // 选择对象;
            document.execCommand('Copy'); // 执行浏览器复制命令
            this.$message({
                message: '复制成功',
                type: 'success',
            });
            oInput.remove();
        },
        onSubmit() {
            if (!this.form.auditStatus) {
                return this.$message.error('请选择审核结果');
            }
            // else if (!this.form.expireTime && this.form.auditStatus == 1) {
            //     return this.$message.error('请选择回收时间');
            // }
            else if (!this.form.libraryCategory && this.form.auditStatus == 1) {
                return this.$message.error('请选择报备库');
            }
            // else if (!this.form.auditRemark) {
            //     return this.$message.error('请输入审核备注');
            // }
            else if (
                this.nicheDomainDOList.length == 0 &&
                this.tableRow.nicheDomainBOList.some((item) => {
                    return item.auditStatus == 0;
                })
            ) {
                return this.$message.error('请先审核域名');
            } else {
                if (
                    !this.nicheDomainDOList.some((item) => {
                        return item.auditStatus == 1;
                    }) &&
                    this.form.auditStatus == 1
                ) {
                    return this.$message.error(
                        '报备成功至少需要有1个域名报备成功'
                    );
                }
                if (this.form.auditStatus == 1 && this.surplus <= 0) {
                    return this.$message.error(
                        '当前报备库名额不足，请选择其他报备库'
                    );
                }
                if (
                    this.form.auditStatus == 1 &&
                    this.tableRow.nicheDomainBOList.some((item) => {
                        return item.auditStatus == 1;
                    }) &&
                    this.nicheDomainDOList.length == 0
                ) {
                    return this.$message.error(
                        '报备成功至少需要有1个域名报备成功'
                    );
                }
                let data = {
                    param: this.form,
                };
                if (this.form.auditStatus == 2) {
                    delete data.param.libraryCategory;
                    delete data.param.expireTime;
                }
                data.param.id = this.tableRow.nicheAuditBO.id;
                data.param.nicheDomainDOList = this.nicheDomainDOList;
                reportAudit(data).then((res) => {
                    if (res.code == 200) {
                        this.$message.success('操作成功');
                        for (let i in this.form) {
                            this.form[i] = '';
                        }
                        this.followTime = '';
                        this.onClose();
                    }
                });
            }
        },
        onStop() {
            if (
                this.tableRow.nicheAuditBO.auditAdminId !=
                    sessionStorage.getItem('adminId') &&
                this.tableRow.nicheAuditBO.auditAdminId != 0
            ) {
                return this.$message.error('该条数据无法处理');
            }
            let data = {
                param: {
                    id: this.tableRow.id,
                    reportStatus: Number(this.tableRow.reportStatus) - 1,
                },
            };
            reportUpdate(data).then((res) => {
                if (res.code == 200) {
                    this.$message.success('操作成功');

                    this.expireTimeBtn = '';
                    this.onClose();
                }
            });
        },
        onClose() {
            for (let i in this.form) {
                this.form[i] = '';
            }
            this.$emit('close');
        },
    },
};
</script>

<style lang="scss" scoped>
.apply_box {
    width: 100%;
    .title {
        height: 60px;
        line-height: 60px;
        padding-left: 30px;
        padding-right: 25px;
        font-size: 14px;
        font-weight: 600;
        border-bottom: 1px solid #e5e5e5;
        button {
            float: right;
            width: 80px;
            height: 32px;
            background: #2370eb;
            border-radius: 2px;
            line-height: 28px;
            padding: 0;
            color: #fff;
            margin-top: 15px;
        }
    }
    .info {
        border-bottom: 1px dashed #e5e5e5;
        padding-bottom: 20px;
    }
    .audit {
        padding-bottom: 50px;
    }
    .brand {
        display: inline-block;
        width: 240px;
        box-sizing: border-box;
        padding-left: 10px;
        color: #333333;
        font-size: 14px;
        border-radius: 2px;
    }
    .el-form {
        margin-left: 40px;
        margin-top: 20px;
    }
}
.info {
    /deep/ .el-form-item {
        margin-bottom: 0px !important;
    }
}
.bottom-but {
    width: 480px;
    line-height: 48px;
    text-align: right;
    background: #fff;
    border-top: 1px solid #dee0e3;
    z-index: 1000;
    bottom: 0;
    position: fixed;
    button {
        width: 90px;
        z-index: 1000;
    }
}
/deep/ .el-textarea__inner {
    resize: none;
}
/deep/ .el-radio-button--mini .el-radio-button__inner {
    padding: 5px 10px !important;
}
.faid {
    /deep/ .el-radio-button__orig-radio:checked + .el-radio-button__inner {
        color: #d0021b !important;
        border-color: #d0021b !important;
    }
}
.success2 {
    width: 45px;
    height: 20px;
    margin-left: 5px;
    border-radius: 3px;
    line-height: 20px;
    display: inline-block;
    text-align: center;
    font-size: 12px;
    border: 1px solid #2370eb;
    color: #2370eb;
}
.warning2 {
    font-size: 12px;
    width: 45px;
    height: 20px;
    margin-left: 5px;
    border-radius: 3px;
    line-height: 20px;
    display: inline-block;
    text-align: center;
    border: 1px solid #d0021b;
    color: #d0021b;
}
</style>
