<template>
    <div class="table-box">
        <el-table
            ref="multipleTable"
            :data="tableData"
            height="100%"
            :header-cell-style="{
                background: '#FAFAFA',
                color: '#333333',
                'font-size': '14px',
                'font-weight': '500',
            }"
        >
            <el-table-column width="10" show-overflow-tooltip>
            </el-table-column>
            <el-table-column label="报备名称" width="239" show-overflow-tooltip>
                <template slot-scope="scope">
                    <el-button
                        type="text"
                        style="color: #2370eb"
                        @click="onReportDetail(scope.row)"
                        >{{ scope.row.reportName || '- -' }}</el-button
                    >
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column
                label="域名"
                show-overflow-tooltip
                :formatter="returnDomain"
                width="145"
            >
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column
                label="申请人"
                prop="companyDomain"
                show-overflow-tooltip
                width="55"
            >
                <template slot-scope="scope">
                    <div>
                        {{ scope.row.adminName || '- -' }}
                    </div>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column label="申请时间" show-overflow-tooltip width="93">
                <template slot-scope="scope">
                    <span
                        v-if="
                            scope.row.nicheAuditBO &&
                            scope.row.nicheAuditBO.createTime
                        "
                        >{{ scope.row.nicheAuditBO.createTime }}</span
                    >
                    <span v-else>- -</span>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>

            <el-table-column label="状态" width="120" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span>
                        {{
                            $MailStatus.reportStatus(scope.row.reportStatus)
                        }}</span
                    >
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column label="试用状态" width="120" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span>
                        {{
                            $MailStatus.trialStatus(scope.row.trialStatus)
                        }}</span
                    >
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column label="品牌" show-overflow-tooltip width="110">
                <template slot-scope="scope">
                    <span>{{
                        scope.row.productType == 1
                            ? '腾讯企业邮箱'
                            : scope.row.productType == 2
                            ? '阿里企业邮箱'
                            : '网易企业邮箱'
                    }}</span>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column
                label="业务名称"
                prop="name"
                show-overflow-tooltip
                :formatter="returnName"
                width="80"
            >
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column label="操作" fixed="right" width="60">
                <template slot-scope="scope">
                    <el-button
                        style="color: #2370eb"
                        type="text"
                        @click="onHandle2(scope.row)"
                        >处理</el-button
                    >
                </template>
            </el-table-column>
            <div slot="empty" class="empty">
                <img src="@/assets/zanwushuju.png" />
                <span style="display: block">暂无数据～</span>
            </div>
        </el-table>
        <!-- 申请处理 -->
        <el-dialog
            :visible.sync="dialog"
            width="560px"
            :before-close="handleDialog"
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        color: #000000;
                        font-size: 16px;
                        font-weight: 500;
                    "
                >
                    申请处理
                </div>
            </template>
            <p class="dl_con">
                <i class="el-icon-warning-outline"></i>
                是否确认申请处理该报备？
            </p>
            <div slot="footer" class="dialog_footer">
                <el-button
                    @click="dialog = false"
                    round
                    size="mini"
                    style="width: 70px; height: 27px"
                    >取 消</el-button
                >
                <el-button
                    type="primary"
                    style="width: 70px; height: 27px"
                    @click="onHandle(tableRow)"
                    round
                    size="mini"
                    >确 定</el-button
                >
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { reportList, reportGet } from '@/api/report/report.js';
export default {
    name: '',
    props: {},
    data() {
        return {
            tableData: [],
            tableRow: {},
            dialog: false,
        };
    },
    components: {},
    created() {},
    methods: {
        getData(active, name, searchVal, pageNum, pageSize, btnP) {
            this.btnP = btnP;
            let data = {
                param: {
                    auditAdminId: sessionStorage.getItem('adminId'),
                },
                pageSize: pageSize,
                pageNum: pageNum,
            };
            var startTime = '';
            var endTime = '';
            if (searchVal.followTime != '') {
                let date = new Date();
                // searchVal.followDate = [];
                if (searchVal.followTime == '今日') {
                    endTime = this.$searchTime.getNextDate(date, 0);
                    startTime = this.$searchTime.getNextDate(date, 0);
                } else if (
                    searchVal.followTime == '近7天' &&
                    (searchVal.field == 1 || searchVal.field == 3)
                ) {
                    startTime = this.$searchTime.getNextDate(date, 0);
                    endTime = this.$searchTime.getNextDate(date, -6);
                } else if (
                    searchVal.followTime == '近7天' &&
                    (searchVal.field == 2 || searchVal.field == 4)
                ) {
                    startTime = this.$searchTime.getNextDate(date, 7);
                    endTime = this.$searchTime.getNextDate(date, 0);
                } else if (searchVal.followTime == '本月') {
                    startTime = this.$searchTime.DateUtil.getStartDayOfMonth();
                    endTime = this.$searchTime.DateUtil.getEndDayOfMonth();
                }
            }
            if (searchVal.followDate && searchVal.followDate.length > 0) {
                startTime = searchVal.followDate[0];
                endTime = searchVal.followDate[1];
            }
            if (startTime && endTime) {
                if (searchVal.field == 1) {
                    data.param.startTrialExpireDate = startTime;
                    data.param.endTrialExpireDate = endTime;
                } else if (searchVal.field == 2) {
                    data.param.startReportStartDate = startTime;
                    data.param.endReportStartDate = endTime;
                } else if (searchVal.field == 3) {
                    data.param.startReportExpireDate = startTime;
                    data.param.endReportExpireDate = endTime;
                } else if (searchVal.field == 4) {
                    data.param.startCreateDate = startTime;
                    data.param.endCreateDate = endTime;
                }
            }

            if (name) {
                data.param.name = name;
            }
            switch (active) {
                case '全部':
                    data.param.reportStatusList = [
                        8, 9, 11, 12, 13, 14, 15, 16,
                    ];
                    data.param.trialStatusList = [
                        7, 8, 10, 11, 13, 14, 15, 16, 18, 19,
                    ];
                    data.param.all = 1;
                    data.param.productTypeList = [];
                    if (this.btnP.aliBusiness) {
                        data.param.productTypeList.push(2);
                    }
                    if (this.btnP.tmBusiness) {
                        data.param.productTypeList.push(1);
                    }
                    if (this.btnP.wyBusiness) {
                        data.param.productTypeList.push(4);
                    }
                    // if (
                    //     this.btnP.aliBusiness &&
                    //     this.btnP.tmBusiness &&
                    //     this.btnP.wyBusiness
                    // ) {
                    //     delete data.param.productTypeList;
                    // }
                    // data.param.all = 1;
                    // delete data.param.productTypeList;
                    break;
                case '阿里报备':
                    data.param.reportStatusList = [
                        8, 9, 11, 12, 13, 14, 15, 16,
                    ];
                    data.param.productType = 2;
                    break;
                case '腾讯报备':
                    data.param.reportStatusList = [
                        8, 9, 11, 12, 13, 14, 15, 16,
                    ];
                    data.param.productType = 1;
                    break;
                case '网易报备':
                    data.param.reportStatusList = [
                        8, 9, 11, 12, 13, 14, 15, 16,
                    ];
                    data.param.productType = 4;
                    break;
                case '阿里试用':
                    data.param.reportStatusList = [3];
                    data.param.trialStatusList = [7, 8, 10, 11, 13, 14, 15, 16];
                    data.param.productType = 2;
                    break;
                case '腾讯试用':
                    data.param.reportStatusList = [3];
                    data.param.trialStatusList = [
                        7, 8, 10, 11, 13, 14, 15, 16, 18, 19,
                    ];
                    data.param.productType = 1;
                    break;
                case '网易试用':
                    data.param.reportStatusList = [3];
                    data.param.trialStatusList = [7, 8, 10, 11, 13, 14, 15, 16];
                    data.param.productType = 4;
                    break;
            }
            this.pageData = {
                searchVal,
                name,
                active,
                pageNum,
                pageSize,
            };
            reportList(data).then((res) => {
                if (res.code == 200) {
                    this.$emit('totalNum', res.data.total);
                    this.tableData = res.data.list;
                }
            });
        },
        handleDialog() {
            this.dialog = false;
        },
        onHandle2(row) {
            this.tableRow = row;
            if (
                row.reportStatus == 8 ||
                row.reportStatus == 11 ||
                row.reportStatus == 13 ||
                row.reportStatus == 15 ||
                row.trialStatus == 7 ||
                row.trialStatus == 10 ||
                row.trialStatus == 13 ||
                row.trialStatus == 15 ||
                row.trialStatus == 18
            ) {
                this.reportGet();
            } else {
                this.onHandle(row);
            }
        },
        // 获取单条数据
        reportGet() {
            let data = {
                param: {
                    id: this.tableRow.id,
                },
            };
            reportGet(data).then((res) => {
                if (res.code == 200) {
                    if (
                        res.data &&
                        (res.data.nicheAuditBO.auditAdminId ==
                            sessionStorage.getItem('adminId') ||
                            res.data.nicheAuditBO.auditAdminId == 0)
                    ) {
                        this.dialog = true;
                    } else {
                        return this.$message.error('无权操作');
                    }
                }
            });
        },
        onHandle(row) {
            this.dialog = false;
            this.$emit('onHandle', row);
        },
        // 域名处理
        returnDomain(row) {
            if (row.productType == 1) {
                return row.reportDomain;
            }
            if (row.productType == 2) {
                if (row.nicheDomainBOList && row.nicheDomainBOList.length > 0) {
                    return row.nicheDomainBOList[0].domain;
                } else {
                    return '- -';
                }
            }
            if (row.productType == 4) {
                if (row.nicheDomainBOList && row.nicheDomainBOList.length > 0) {
                    return row.nicheDomainBOList[0].domain;
                } else {
                    return '- -';
                }
            }
        },
        onHistory(row, num) {
            this.$emit('onHistory', {
                row,
                num,
            });
        },
        onReportDetail(row) {
            sessionStorage.setItem('pageData', JSON.stringify(this.pageData));
            if (row.productType == 1) {
                this.$router.push({
                    path: '/tmDetail',
                    query: {
                        id: row.id,
                        collaborator: this.btnP.collaborator || false,
                    },
                });
            } else if (row.productType == 2) {
                this.$router.push({
                    path: '/aliReportingdetails',
                    query: {
                        id: row.id,
                    },
                });
            } else {
                this.$router.push({
                    path: '/wyReportingdetails',
                    query: {
                        id: row.id,
                    },
                });
            }
        },
        // 业务名称
        returnName(row) {
            if (row.reportStatus == 8 || row.reportStatus == 9) {
                return '报备';
            }
            if (row.reportStatus == 11 || row.reportStatus == 12) {
                return '报备延期';
            }
            if (row.reportStatus == 13 || row.reportStatus == 14) {
                return '放弃报备';
            }
            if (row.reportStatus == 15 || row.reportStatus == 16) {
                return '添加域名';
            }
            if (row.trialStatus == 7 || row.trialStatus == 8) {
                return '试用';
            }
            if (row.trialStatus == 10 || row.trialStatus == 11) {
                return '停止试用';
            }
            if (row.trialStatus == 13 || row.trialStatus == 14) {
                return '试用延期';
            }
            if (row.trialStatus == 15 || row.trialStatus == 16) {
                return '变更试用';
            }
            if (row.trialStatus == 18 || row.trialStatus == 19) {
                return '重新试用';
            }
            return '- -';
        },
    },
};
</script>

<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.table-box {
    width: 100%;
    height: 100%;
    .icon-ziyuanxhdpi {
        color: #2370eb;
        cursor: pointer;
        vertical-align: -1px;
    }
}
// /deep/ .el-table th:nth-child(6) .cell {
//     padding-left: 20px;
// }
// /deep/ td:nth-child(6) .cell {
//     padding-left: 0;
// }
// /deep/ .el-table th:nth-child(7) .cell {
//     padding-left: 20px;
// }
// /deep/ td:nth-child(7) .cell {
//     padding-left: 0;
// }
/deep/ .el-table .cell {
    text-overflow: initial;
    padding-right: 0;
}
.dl_con {
    width: 80%;
    margin: 35px auto 100px;
    text-align: center;
    color: #333;
    font-weight: 600;
    font-size: 14px;
    i {
        font-weight: 400;
        font-size: 60px;
        color: #f5a623;
        display: block;
        margin-bottom: 20px;
    }
}
/deep/.el-dialog__footer {
    padding: 0;
    text-align: right;
    height: 50px;
    line-height: 50px;
    border-top: 1px solid #eeeeee;
    padding-right: 18px;
    .el-button--primary {
        background-color: #2370eb;
    }
}
</style>
