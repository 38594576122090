<template>
    <div class="apply_box">
        <div class="title">
            试用申请处理中
            <el-button @click="onStop">中止处理</el-button>
        </div>
        <div class="info">
            <el-form ref="form" label-position="left" size="small" label-width="90px">
                <el-form-item label="报备品牌" style="margin-left: -10px">
                    <span class="brand">阿里企业邮箱</span>
                </el-form-item>
                <el-form-item label="报备名称" style="margin-left: -10px">
                    <span class="brand">{{ tableRow.reportName || '- -' }}</span>
                    <el-button
                        type="text"
                        style="color: #2370eb; font-size: 16px; padding: 6px 0"
                        @click.stop="onCopy"
                        v-if="tableRow.reportName"
                        class="iconfont icon-fuzhi1"
                    ></el-button>
                </el-form-item>
                <el-form-item
                    :label="tableRow.nicheCategory == 1 ? '客户' : '报备渠道'"
                    style="margin-left: -10px"
                >
                    <span class="brand">{{
                        tableRow.nicheCategory == 1
                            ? tableRow.companyName || '- -'
                            : tableRow.channelManageName || '- -'
                    }}</span>
                </el-form-item>
                <!-- v-for="item in tableRow.nicheDomainBOList"
                    :key="item.id" -->
                <el-form-item label="报备库" style="margin-left: -10px">
                    <span class="brand">{{ tableRow.libraryName }}</span>
                </el-form-item>
                <el-form-item label="试用域名" style="margin-left: -10px">
                    <span class="brand">{{ tableRow.trialDomain || '- -' }}</span>
                    <el-button
                        type="text"
                        style="color: #2370eb; font-size: 16px; padding: 0"
                        @click.stop="onCopy(tableRow.trialDomain)"
                        v-if="tableRow.trialDomain"
                        class="iconfont icon-fuzhi1"
                    ></el-button>
                </el-form-item>
                <el-form-item label="用户数" style="margin-left: -10px">
                    <span class="brand">{{ tableRow.trialNumber || '- -' }}</span>
                    <el-button
                        type="text"
                        style="color: #2370eb; font-size: 16px; padding: 6px 0"
                        @click.stop="onCopy(tableRow.trialNumber)"
                        v-if="tableRow.trialNumber"
                        class="iconfont icon-fuzhi1"
                    ></el-button>
                </el-form-item>
                <el-form-item label="试用天数" style="margin-left: -10px">
                    <span class="brand">{{ tableRow.trialDays || '- -' }}</span>
                </el-form-item>
                <el-form-item label="报备库" style="margin-left: -10px">
                    <span class="brand">{{ tableRow.libraryName || '- -' }}</span>
                </el-form-item>
                <el-form-item label="备注" style="margin-left: -10px">
                    <span class="brand">{{
                        tableRow.nicheAuditBO ? tableRow.nicheAuditBO.applyRemark || '- -' : '- -'
                    }}</span>
                </el-form-item>
            </el-form>
        </div>
        <div class="audit">
            <el-form ref="form" label-position="left" size="small" label-width="90px">
                <el-form-item label="处理结果" required style="margin-left: -10px">
                    <el-radio-group v-model="form.auditStatus">
                        <el-radio :label="1">成功</el-radio>
                        <el-radio :label="2">失败</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item
                    label="试用到期时间"
                    required
                    v-show="!form.auditStatus || form.auditStatus == 1"
                    style="margin-left: -10px"
                >
                    <el-date-picker
                        size="small"
                        v-model="form.expireTime"
                        type="date"
                        value-format="yyyy-MM-dd HH:mm:ss"
                        style="width: 240px"
                        clearable
                        @change="expireTime"
                    >
                    </el-date-picker>
                    <el-radio-group
                        style="display: block; margin-top: 8px"
                        v-model="followTime"
                        size="small"
                    >
                        <el-radio-button
                            label="30天"
                            @click.native.prevent="clickFollow('30天')"
                        ></el-radio-button>
                        <el-radio-button
                            label="60天"
                            @click.native.prevent="clickFollow('60天')"
                        ></el-radio-button>
                        <el-radio-button
                            label="90天"
                            @click.native.prevent="clickFollow('90天')"
                        ></el-radio-button>
                    </el-radio-group>
                </el-form-item>
                <el-form-item
                    label="试用账号"
                    v-show="!form.auditStatus || form.auditStatus == 1"
                    required
                    style="margin-left: -10px"
                >
                    <el-input
                        v-model="form.trialAccount"
                        placeholder="请输入试用账号"
                        size="small"
                        style="width: 240px"
                    ></el-input>
                </el-form-item>
                <el-form-item
                    label="试用密码"
                    required
                    v-show="!form.auditStatus || form.auditStatus == 1"
                    style="margin-left: -10px"
                >
                    <el-input
                        v-model="form.trialPassword"
                        placeholder="请输入试用密码"
                        size="small"
                        style="width: 240px"
                    ></el-input>
                </el-form-item>
                <el-form-item
                    :label="form.auditStatus == 1 ? '账号信息' : '处理备注'"
                    style="margin-left: -10px"
                >
                    <el-input
                        type="textarea"
                        v-model="form.auditRemark"
                        size="small"
                        :placeholder="form.auditStatus == 1 ? '请输入账号信息' : '请输入处理备注'"
                        style="width: 240px"
                        rows="6"
                    ></el-input>
                </el-form-item>
            </el-form>
        </div>
        <div class="bottom-but">
            <el-button
                round
                type="primary"
                size="mini"
                style="background: none; border-color: #d5d6df; color: #666666; margin-left: 270px"
                @click="onClose"
                >取 消</el-button
            >

            <el-button
                round
                type="primary"
                style="background: #2370eb; border-color: #2370eb; color: #fff"
                size="mini"
                @click="onSubmit"
                >保 存</el-button
            >
        </div>
    </div>
</template>

<script>
import { reportAudit, reportUpdate } from '@/api/report/report';
export default {
    name: '',
    props: {},
    data() {
        return {
            tableRow: {},
            form: {
                auditStatus: '',
                // libraryCategory: '',
                auditRemark: '',
                expireTime: '',
                trialAccount: '',
            },
            followTime: '30天',
            radio3: '失败',
            nicheDomainDOList: [],
        };
    },
    components: {},
    created() {},
    methods: {
        getData(row) {
            this.tableRow = row;
            this.form.expireTime = this.$searchTime.getNextDate(new Date(), -30) + ' 00:00:00';
            this.form.trialAccount = 'postmaster@' + row.trialDomain;
        },
        clickFollow(e) {
            e === this.followTime ? (this.followTime = '') : (this.followTime = e);
            let date = new Date();
            if (e == this.followTime && e == '30天') {
                this.form.expireTime = this.$searchTime.getNextDate(new Date(), -30) + ' 00:00:00';
            } else if (e == this.followTime && e == '60天') {
                this.form.expireTime = this.$searchTime.getNextDate(new Date(), -60) + ' 00:00:00';
            } else if (e == this.followTime && e == '90天') {
                this.form.expireTime = this.$searchTime.getNextDate(new Date(), -90) + ' 00:00:00';
            } else {
                this.form.expireTime = '';
            }
        },
        expireTime() {
            this.followTime = '';
        },
        // 复制
        onCopy(item) {
            let url = item;
            let oInput = document.createElement('input');
            oInput.value = url;
            document.body.appendChild(oInput);
            oInput.select(); // 选择对象;
            document.execCommand('Copy'); // 执行浏览器复制命令
            this.$message({
                message: '复制成功',
                type: 'success',
            });
            oInput.remove();
        },
        onSubmit() {
            if (!this.form.auditStatus) {
                return this.$message.error('请选择审核结果');
            } else if (!this.form.expireTime && this.form.auditStatus == 1) {
                return this.$message.error('请选择试用到期时间');
            }
            // else if (!this.form.libraryCategory) {
            //     return this.$message.error('请选择报备库');
            // }
            // else if (!this.form.auditRemark) {
            //     return this.$message.error('请输入审核备注');
            // }
            else if (!this.form.trialAccount && this.form.auditStatus == 1) {
                return this.$message.error('请输入试用账号');
            } else if (!this.form.trialPassword && this.form.auditStatus == 1) {
                return this.$message.error('请输入试用密码');
            } else {
                let data = {
                    param: this.form,
                };
                if (this.form.auditStatus == 2) {
                    delete data.param.expireTime;
                    delete data.param.trialAccount;
                    delete data.param.trialPassword;
                }
                data.param.id = this.tableRow.nicheAuditBO.id;
                reportAudit(data).then((res) => {
                    if (res.code == 200) {
                        this.$message.success('操作成功');
                        for (let i in this.form) {
                            this.form[i] = '';
                        }
                        this.followTime = '';
                        this.onClose();
                    }
                });
            }
        },
        onStop() {
            if (
                this.tableRow.nicheAuditBO.auditAdminId != sessionStorage.getItem('adminId') &&
                this.tableRow.nicheAuditBO.auditAdminId != 0
            ) {
                return this.$message.error('该条数据无法处理');
            }
            let data = {
                param: {
                    id: this.tableRow.id,
                    trialStatus: Number(this.tableRow.trialStatus) - 1,
                },
            };
            reportUpdate(data).then((res) => {
                if (res.code == 200) {
                    this.$message.success('操作成功');

                    this.expireTimeBtn = '';
                    this.onClose();
                }
            });
        },
        onClose() {
            for (let i in this.form) {
                this.form[i] = '';
            }
            this.$emit('close');
        },
    },
};
</script>

<style lang="scss" scoped>
.apply_box {
    width: 100%;
    .title {
        height: 60px;
        line-height: 60px;
        padding-left: 40px;
        padding-right: 25px;
        font-size: 14px;
        font-weight: 600;
        border-bottom: 1px solid #e5e5e5;
        button {
            float: right;
            width: 80px;
            height: 32px;
            background: #2370eb;
            border-radius: 2px;
            line-height: 28px;
            padding: 0;
            color: #fff;
            margin-top: 15px;
        }
    }
    .info {
        border-bottom: 1px dashed #e5e5e5;
        padding-bottom: 20px;
    }
    .audit {
        padding-bottom: 50px;
    }
    .brand {
        display: inline-block;
        width: 240px;
        box-sizing: border-box;
        padding-left: 10px;
        color: #333333;
        font-size: 14px;
        border-radius: 2px;
    }
    .el-form {
        margin-left: 50px;
        margin-top: 20px;
    }
}
.info {
    /deep/ .el-form-item {
        margin-bottom: 0px !important;
    }
}
.bottom-but {
    width: 480px;
    line-height: 48px;
    text-align: right;
    background: #fff;
    border-top: 1px solid #dee0e3;
    z-index: 1000;
    bottom: 0;
    position: fixed;
    button {
        width: 90px;
        z-index: 1000;
    }
}
/deep/ .el-textarea__inner {
    resize: none;
}
/deep/ .el-radio-button--mini .el-radio-button__inner {
    padding: 5px 10px !important;
}
.faid {
    /deep/ .el-radio-button__orig-radio:checked + .el-radio-button__inner {
        color: #d0021b !important;
        border-color: #d0021b !important;
    }
}
</style>
