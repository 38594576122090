<template>
    <!-- 同阿里报备 -->
    <div class="deal_with_box">
        <div class="search-box">
            <div class="search-one">
                <p class="left">
                    <el-select
                        v-model="searchVal.field"
                        size="small"
                        style="width: 100px; margin-right: 10px"
                        placeholder="请选择"
                        class="slee"
                    >
                        <el-option label="试用到期" :value="1"></el-option>
                        <el-option label="报备时间" :value="2"></el-option>
                        <el-option label="报备到期" :value="3"></el-option>
                        <el-option label="创建时间" :value="4"></el-option>
                    </el-select>
                    <el-radio-group v-model="searchVal.followTime" size="small">
                        <el-radio-button
                            label="今日"
                            @click.native.prevent="clickFollow('今日')"
                        ></el-radio-button>
                        <el-radio-button
                            label="近7天"
                            @click.native.prevent="clickFollow('近7天')"
                        ></el-radio-button>
                        <el-radio-button
                            label="本月"
                            @click.native.prevent="clickFollow('本月')"
                        ></el-radio-button>
                    </el-radio-group>
                    <el-date-picker
                        size="small"
                        v-model="searchVal.followDate"
                        type="daterange"
                        value-format="yyyy-MM-dd"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        style="width: 240px; margin-left: 19px"
                        clearable
                        @change="expireTime"
                    >
                    </el-date-picker>
                    <span>查询</span>
                    <el-input
                        size="small"
                        style="width: 215px"
                        placeholder="报备名称、客户名称、域名名称"
                        v-model.trim="name"
                        clearable
                    >
                    </el-input>
                    <el-button
                        @click="getTableData()"
                        type="primary"
                        size="small"
                        icon="el-icon-search"
                    ></el-button>
                </p>
                <p>
                    <el-button
                        type="primary"
                        style="
                            margin-right: 16px;
                            border-color: #2370eb;
                            background: #2370eb;
                        "
                        size="small"
                        @click="getTableData2()"
                        >查询</el-button
                    >
                    <el-button
                        @click="searchEmpty"
                        type="primary"
                        style="
                            margin: 0;
                            background: none;
                            border-color: #d9d9d9;
                            color: #333333;
                            margin-right: 16px;
                        "
                        icon="el-icon-refresh-left"
                        size="small"
                        >重置</el-button
                    >
                </p>
            </div>
        </div>
        <div class="table-title">
            <div>
                <div class="title_left_btn">
                    <span
                        @click="getTableData2($event)"
                        :class="active == '全部' ? 'active' : ''"
                        >全部</span
                    >
                    <span
                        @click="getTableData2($event)"
                        :class="active == '阿里报备' ? 'active' : ''"
                        v-if="btnP.aliBusiness"
                        >阿里报备</span
                    >
                    <span
                        @click="getTableData2($event)"
                        :class="active == '腾讯报备' ? 'active' : ''"
                        v-if="btnP.tmBusiness"
                        >腾讯报备</span
                    >
                    <span
                        @click="getTableData2($event)"
                        :class="active == '网易报备' ? 'active' : ''"
                        v-if="btnP.wyBusiness"
                        >网易报备</span
                    >
                    <span
                        @click="getTableData2($event)"
                        :class="active == '阿里试用' ? 'active' : ''"
                        v-if="btnP.aliBusiness"
                        >阿里试用</span
                    >
                    <span
                        @click="getTableData2($event)"
                        :class="active == '腾讯试用' ? 'active' : ''"
                        v-if="btnP.tmBusiness"
                        >腾讯试用</span
                    >
                    <span
                        @click="getTableData2($event)"
                        :class="active == '网易试用' ? 'active' : ''"
                        v-if="btnP.wyBusiness"
                        >网易试用</span
                    >
                </div>
            </div>
        </div>
        <Table
            ref="table"
            @onHandle="onHandle"
            @onHistory="onHistory"
            @onReportDetail="onReportDetail"
            @totalNum="totalNum"
        ></Table>
        <div class="page-box">
            <el-pagination
                v-if="isPage"
                @current-change="handleCurrentChange"
                @size-change="handleSizeChange"
                :current-page.sync="currentPage"
                :page-size="pagesize"
                :page-sizes="[10, 20, 50]"
                layout="total,sizes,prev, pager, next, jumper"
                :total="total"
            ></el-pagination>
        </div>
        <!-- 报备申请处理 -->
        <el-drawer
            :visible.sync="drawerAli"
            :direction="direction"
            :before-close="handleDrawer"
            size="500px"
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        font-size: 16px;
                        font-weight: 500;
                        color: rgba(0, 0, 0, 0.85);
                        line-height: 24px;
                    "
                >
                    {{ drawerTitle }}
                </div>
            </template>
            <AliApply
                @close="handleDrawer"
                ref="aliApply"
                v-if="isDrawer == 1"
            />
            <AliTrial
                @close="handleDrawer"
                ref="aliTrial"
                v-if="isDrawer == 2"
            />
            <AddDomain
                @close="handleDrawer"
                ref="addDomain"
                v-if="isDrawer == 3"
            />
            <AliDealy
                @close="handleDrawer"
                ref="aliDealy"
                v-if="isDrawer == 4"
            />
            <AliGiveUp
                @close="handleDrawer"
                ref="aliGiveUp"
                v-if="isDrawer == 5"
            />
            <AliChangeTrial
                @close="handleDrawer"
                ref="aliChangeTrial"
                v-if="isDrawer == 6"
            />
            <TmReport
                @close="handleDrawer"
                ref="tmReport"
                v-if="isDrawer == 7"
            />
            <TmReportDealy
                @close="handleDrawer"
                ref="tmReportDealy"
                v-if="isDrawer == 8"
            />
            <TmTrial @close="handleDrawer" ref="tmTrial" v-if="isDrawer == 9" />
            <TmTrialDelay
                @close="handleDrawer"
                ref="tmTrialDelay"
                v-if="isDrawer == 10"
            />
            <TmreportGiveUp
                @close="handleDrawer"
                ref="tmreportGiveUp"
                v-if="isDrawer == 11"
            />
            <TmtrialGiveUp
                @close="handleDrawer"
                ref="tmtrialGiveUp"
                v-if="isDrawer == 12"
            />
            <TmResetTrial
                @close="handleDrawer"
                ref="tmResetTrial"
                v-if="isDrawer == 13"
            />
            <WyApply
                @close="handleDrawer"
                ref="wyApply"
                v-if="isDrawer == 14"
            />
            <WyAddDomain
                @close="handleDrawer"
                ref="wyAddDomain"
                v-if="isDrawer == 15"
            />
            <WyTrial
                @close="handleDrawer"
                ref="wyTrial"
                v-if="isDrawer == 16"
            />
            <WyDealy
                @close="handleDrawer"
                ref="wyDealy"
                v-if="isDrawer == 17"
            />
            <WyGiveUp
                @close="handleDrawer"
                ref="wyGiveUp"
                v-if="isDrawer == 18"
            />
            <WyChangeTrial
                @close="handleDrawer"
                ref="wyChangeTrial"
                v-if="isDrawer == 19"
            />
            <WyTrialDealy
                @close="handleDrawer"
                ref="wyTrialDealy"
                v-if="isDrawer == 20"
            />
        </el-drawer>
        <!-- 报备申请处理 -->
        <el-drawer
            :visible.sync="drawerHistory"
            :direction="direction"
            :before-close="handleDrawer"
            size="500px"
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        font-size: 16px;
                        font-weight: 500;
                        color: rgba(0, 0, 0, 0.85);
                        line-height: 24px;
                    "
                >
                    操作记录
                </div>
            </template>
            <History @close="handleDrawer" ref="history" />
        </el-drawer>
        <!-- 报备申请处理 -->
        <el-drawer
            :visible.sync="drawerDetails"
            :direction="direction"
            :before-close="handleDrawer"
            size="500px"
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        font-size: 16px;
                        font-weight: 500;
                        color: rgba(0, 0, 0, 0.85);
                        line-height: 24px;
                    "
                >
                    报备信息
                </div>
            </template>
            <Details @close="handleDrawer" ref="details" />
        </el-drawer>
    </div>
</template>

<script>
import Table from './table/table.vue';
import AliApply from './aliProc/aliApply/apply.vue';
import AddDomain from './aliProc/addDomain/apply.vue';
import AliDealy from './aliProc/aliDelay/apply.vue';
import AliGiveUp from './aliProc/aliGiveUp/apply.vue';
import AliChangeTrial from './aliProc/aliChangeTrial/apply.vue';
import AliTrial from './aliProc/aliTrial/apply.vue';
import TmReport from './tmProc/report.vue';
import TmReportDealy from './tmProc/reportDelay.vue';
import TmTrial from './tmProc/trial.vue';
import TmResetTrial from './tmProc/resetTrial.vue';
import TmTrialDelay from './tmProc/trialDelay.vue';
import TmreportGiveUp from './tmProc/reportGiveUp.vue';
import TmtrialGiveUp from './tmProc/trialGiveUp.vue';
import History from '../aliReporting/history/history.vue';
import Details from './details/details.vue';
import WyApply from './wyProc/wyApply/apply.vue';
import WyAddDomain from './wyProc/addDomain/apply.vue';
import WyDealy from './wyProc/wyDelay/apply.vue';
import WyGiveUp from './wyProc/wyGiveUp/apply.vue';
import WyChangeTrial from './wyProc/wyChangeTrial/apply.vue';
import WyTrial from './wyProc/wyTrial/apply.vue';
import WyTrialDealy from './wyProc/wyDelay/trialApply.vue';
import { reportGet, reportUpdate, reportHistory } from '@/api/report/report.js';
export default {
    data() {
        return {
            active: '全部',
            currentPage: 1,
            pagesize: 20,
            total: 0,
            searchVal: {
                field: 1,
                followTime: '',
                followDate: [],
            },
            field: 1,
            direction: 'rtl',
            followTime: '',
            followDate: '',
            tableRow: {},
            drawerAli: false,
            drawerHistory: false,
            drawerDetails: false,
            name: '',
            isDrawer: 0,
            drawerTitle: '',
            btnP: {},
            isPage: false,
        };
    },
    components: {
        Table,
        AliApply,
        AddDomain,
        AliDealy,
        AliGiveUp,
        AliChangeTrial,
        AliTrial,
        TmReport,
        TmReportDealy,
        TmTrial,
        TmTrialDelay,
        TmreportGiveUp,
        TmtrialGiveUp,
        History,
        Details,
        TmResetTrial,
        WyApply,
        WyAddDomain,
        WyDealy,
        WyGiveUp,
        WyChangeTrial,
        WyTrial,
        WyTrialDealy,
    },
    created() {},
    mounted() {
        if (sessionStorage.getItem('pageData')) {
            this.getPageData();
        } else {
            this.isPage = true;
        }
        this.getBtn();
    },
    methods: {
        getPageData() {
            this.currentPage = JSON.parse(
                sessionStorage.getItem('pageData')
            ).pageNum;
            this.pagesize = JSON.parse(
                sessionStorage.getItem('pageData')
            ).pageSize;
            this.name = JSON.parse(sessionStorage.getItem('pageData')).name;
            this.searchVal = JSON.parse(
                sessionStorage.getItem('pageData')
            ).searchVal;
            this.active = JSON.parse(sessionStorage.getItem('pageData')).active;

            sessionStorage.removeItem('pageData');
            this.isPage = true;
        },
        getBtn() {
            var btnArr = JSON.parse(sessionStorage.getItem('isButArr'));
            let btn = {};
            for (let i in btnArr) {
                btn['' + btnArr[i].route] = true;
            }
            this.btnP = btn;
            this.getData();
        },
        getTableData() {
            this.currentPage = 1;
            this.active = '全部';
            this.searchVal = {
                field: 1,
                followTime: '',
                followDate: [],
            };
            this.getData();
        },
        getTableData2(e) {
            this.currentPage = 1;
            if (e) {
                this.active = e.target.innerText;
            }
            this.getData();
        },
        getData() {
            this.$refs.table.getData(
                this.active,
                this.name,
                this.searchVal,
                this.currentPage,
                this.pagesize,
                this.btnP
            );
        },
        totalNum(row) {
            this.total = row;
        },
        clickFollow(e) {
            e === this.searchVal.followTime
                ? (this.searchVal.followTime = '')
                : (this.searchVal.followTime = e);
            this.searchVal.followDate = [];
        },
        expireTime() {
            if (
                this.searchVal.followDate &&
                this.searchVal.followDate.length > 0
            ) {
                this.searchVal.followTime = '';
            }
        },
        onReportDetail(row) {
            this.tableRow = row;
            this.drawerDetails = true;
            this.reportGet();
        },
        // 分页
        handleCurrentChange(val) {
            this.currentPage = val;
            this.getData();
        },
        handleSizeChange(val) {
            this.pagesize = val;
            this.getData();
        },
        onHandle(row) {
            this.tableRow = row;
            this.drawerAli = true;
            this.reportGet();
        },
        // 获取单条数据
        reportGet() {
            let data = {
                param: {
                    id: this.tableRow.id,
                },
            };
            reportGet(data).then((res) => {
                if (res.code == 200) {
                    if (this.drawerDetails) {
                        this.$refs.details.getData(res.data);
                        return;
                    }
                    if (res.data.productType == 1) {
                        if (
                            res.data.reportStatus == 8 ||
                            res.data.reportStatus == 9
                        ) {
                            this.drawerTitle = '报备审核';
                            this.isDrawer = 7;
                            if (res.data.reportStatus == 8) {
                                this.updateStatus(res.data, 9, 1);
                            }
                            res.data.reportStatus = 9;
                            setTimeout(() => {
                                this.$refs.tmReport.getData(res.data);
                            }, 100);
                        }
                        if (
                            res.data.reportStatus == 11 ||
                            res.data.reportStatus == 12
                        ) {
                            this.drawerTitle = '报备延期审核';
                            if (res.data.reportStatus == 11) {
                                this.updateStatus(res.data, 12, 1);
                            }
                            this.isDrawer = 8;
                            res.data.reportStatus = 12;
                            setTimeout(() => {
                                this.$refs.tmReportDealy.getData(res.data);
                            }, 100);
                        }

                        if (
                            res.data.trialStatus == 7 ||
                            res.data.trialStatus == 8
                        ) {
                            this.drawerTitle = '试用审核';
                            this.isDrawer = 9;
                            if (res.data.trialStatus == 7) {
                                this.updateStatus(res.data, 8, 2);
                            }
                            res.data.trialStatus = 8;
                            setTimeout(() => {
                                this.$refs.tmTrial.getData(res.data);
                            }, 100);
                        }
                        if (
                            res.data.trialStatus == 13 ||
                            res.data.trialStatus == 14
                        ) {
                            this.drawerTitle = '试用延期审核';
                            this.isDrawer = 10;
                            if (res.data.trialStatus == 13) {
                                this.updateStatus(res.data, 14, 2);
                            }
                            res.data.trialStatus = 14;
                            setTimeout(() => {
                                this.$refs.tmTrialDelay.getData(res.data);
                            }, 100);
                        }
                        if (
                            res.data.reportStatus == 13 ||
                            res.data.reportStatus == 14
                        ) {
                            this.drawerTitle = '放弃报备审核';
                            this.isDrawer = 11;
                            if (res.data.reportStatus == 13) {
                                this.updateStatus(res.data, 14, 1);
                            }
                            res.data.reportStatus = 14;
                            setTimeout(() => {
                                this.$refs.tmreportGiveUp.getData(res.data);
                            }, 100);
                        }
                        if (
                            res.data.trialStatus == 10 ||
                            res.data.trialStatus == 11
                        ) {
                            this.drawerTitle = '停止试用审核';
                            this.isDrawer = 12;
                            if (res.data.trialStatus == 10) {
                                this.updateStatus(res.data, 11, 2);
                            }
                            res.data.trialStatus = 11;
                            setTimeout(() => {
                                this.$refs.tmtrialGiveUp.getData(res.data);
                            }, 100);
                        }
                        if (
                            res.data.trialStatus == 18 ||
                            res.data.trialStatus == 19
                        ) {
                            this.drawerTitle = '重新试用审核';
                            this.isDrawer = 13;
                            if (res.data.trialStatus == 18) {
                                this.updateStatus(res.data, 19, 2);
                            }
                            res.data.trialStatus = 19;
                            setTimeout(() => {
                                this.$refs.tmResetTrial.getData(res.data);
                            });
                        }
                    } else if (res.data.productType == 2) {
                        if (
                            res.data.reportStatus == 8 ||
                            res.data.reportStatus == 9
                        ) {
                            this.drawerTitle = '报备审核';
                            this.isDrawer = 1;
                            if (res.data.reportStatus == 8) {
                                this.updateStatus(res.data, 9, 1);
                            }
                            res.data.reportStatus = 9;

                            setTimeout(() => {
                                this.$refs.aliApply.getData(res.data);
                            });
                        }
                        if (
                            res.data.reportStatus == 11 ||
                            res.data.reportStatus == 12
                        ) {
                            this.drawerTitle = '报备延期审核';
                            if (res.data.reportStatus == 11) {
                                this.updateStatus(res.data, 12, 1);
                            }
                            this.isDrawer = 4;
                            res.data.reportStatus = 12;
                            setTimeout(() => {
                                this.$refs.aliDealy.getData(res.data);
                            });
                        }
                        if (
                            res.data.reportStatus == 15 ||
                            res.data.reportStatus == 16
                        ) {
                            this.drawerTitle = '添加域名审核';
                            if (res.data.reportStatus == 15) {
                                this.updateStatus(res.data, 16, 1);
                            }
                            this.isDrawer = 3;
                            res.data.reportStatus = 16;
                            setTimeout(() => {
                                this.$refs.addDomain.getData(res.data);
                            });
                        }
                        if (
                            res.data.trialStatus == 7 ||
                            res.data.trialStatus == 8
                        ) {
                            this.drawerTitle = '试用审核';
                            this.isDrawer = 2;
                            if (res.data.trialStatus == 7) {
                                this.updateStatus(res.data, 8, 2);
                            }
                            res.data.trialStatus = 8;
                            setTimeout(() => {
                                this.$refs.aliTrial.getData(res.data);
                            });
                        }

                        if (
                            res.data.trialStatus == 15 ||
                            res.data.trialStatus == 16
                        ) {
                            this.drawerTitle = '变更试用审核';
                            this.isDrawer = 6;
                            if (res.data.trialStatus == 15) {
                                this.updateStatus(res.data, 16, 2);
                            }
                            res.data.trialStatus = 16;
                            setTimeout(() => {
                                this.$refs.aliChangeTrial.getData(res.data);
                            });
                        }
                        if (
                            res.data.reportStatus == 13 ||
                            res.data.reportStatus == 14
                        ) {
                            this.drawerTitle = '放弃报备审核';
                            this.isDrawer = 5;
                            if (res.data.reportStatus == 13) {
                                this.updateStatus(res.data, 14, 1);
                            }
                            res.data.reportStatus = 14;
                            setTimeout(() => {
                                this.$refs.aliGiveUp.getData(res.data);
                            });
                        }
                    } else if (res.data.productType == 4) {
                        if (
                            res.data.reportStatus == 8 ||
                            res.data.reportStatus == 9
                        ) {
                            this.drawerTitle = '报备审核';
                            this.isDrawer = 14;
                            if (res.data.reportStatus == 8) {
                                this.updateStatus(res.data, 9, 1);
                            }
                            res.data.reportStatus = 9;
                            setTimeout(() => {
                                this.$refs.wyApply.getData(res.data);
                            }, 100);
                        }

                        if (
                            res.data.reportStatus == 15 ||
                            res.data.reportStatus == 16
                        ) {
                            this.drawerTitle = '添加域名审核';
                            if (res.data.reportStatus == 15) {
                                this.updateStatus(res.data, 16, 1);
                            }
                            this.isDrawer = 15;
                            res.data.reportStatus = 16;
                            setTimeout(() => {
                                this.$refs.wyAddDomain.getData(res.data);
                            });
                        }

                        if (
                            res.data.trialStatus == 7 ||
                            res.data.trialStatus == 8
                        ) {
                            this.drawerTitle = '试用审核';
                            this.isDrawer = 16;
                            if (res.data.trialStatus == 7) {
                                this.updateStatus(res.data, 8, 2);
                            }
                            res.data.trialStatus = 8;
                            setTimeout(() => {
                                this.$refs.wyTrial.getData(res.data);
                            }, 100);
                        }
                        if (
                            res.data.reportStatus == 11 ||
                            res.data.reportStatus == 12
                        ) {
                            this.drawerTitle = '报备延期审核';
                            this.isDrawer = 17;
                            if (res.data.reportStatus == 11) {
                                this.updateStatus(res.data, 12, 1);
                            }
                            res.data.reportStatus = 12;
                            setTimeout(() => {
                                this.$refs.wyDealy.getData(res.data);
                            }, 100);
                        }
                        if (
                            res.data.trialStatus == 13 ||
                            res.data.trialStatus == 14
                        ) {
                            this.drawerTitle = '试用延期审核';
                            this.isDrawer = 20;
                            if (res.data.trialStatus == 13) {
                                this.updateStatus(res.data, 14, 2);
                            }
                            res.data.trialStatus = 14;
                            setTimeout(() => {
                                this.$refs.wyTrialDealy.getData(res.data);
                            }, 100);
                        }
                        if (
                            res.data.reportStatus == 13 ||
                            res.data.reportStatus == 14
                        ) {
                            this.drawerTitle = '放弃报备审核';
                            this.isDrawer = 18;
                            if (res.data.reportStatus == 13) {
                                this.updateStatus(res.data, 14, 1);
                            }
                            res.data.reportStatus = 14;
                            setTimeout(() => {
                                this.$refs.wyGiveUp.getData(res.data);
                            }, 100);
                        }
                        if (
                            res.data.trialStatus == 15 ||
                            res.data.trialStatus == 16
                        ) {
                            this.drawerTitle = '变更试用审核';
                            this.isDrawer = 19;
                            if (res.data.trialStatus == 15) {
                                this.updateStatus(res.data, 16, 2);
                            }
                            res.data.trialStatus = 16;
                            setTimeout(() => {
                                this.$refs.wyChangeTrial.getData(res.data);
                            });
                        }
                    }
                }
            });
        },
        onHistory(data) {
            this.drawerHistory = true;
            this.tableRow = data.row;
            this.reportHistory(data.row, data.num);
        },
        // 历史
        reportHistory(row, num) {
            let data = {
                param: {
                    nicheId: this.tableRow.id,
                    nicheType: num,
                },
                pageNum: 0,
                pageSize: 0,
            };
            reportHistory(data).then((res) => {
                if (res.code == 200) {
                    this.$refs.history.getData(row, res.data.list);
                }
            });
        },
        updateStatus(row, i, isType) {
            let data = {
                param: { id: this.tableRow.id },
            };
            if (isType == 1) {
                data.param.reportStatus = i;
            }
            if (isType == 2) {
                data.param.trialStatus = i;
            }
            reportUpdate(data).then((j) => {
                console.log(j);
            });
        },
        searchEmpty() {
            this.searchVal = {
                field: 1,
                followTime: '',
                followDate: [],
            };
            this.name = '';
            this.active = '全部';
            this.currentPage = 1;
            this.getData();
        },
        handleDrawer() {
            this.drawerAli = false;
            this.drawerHistory = false;
            this.drawerDetails = false;
            this.getData();
        },
    },
};
</script>
<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.deal_with_box {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    .search-box {
        width: 100%;
        background: #fff;
        border-radius: 4px;
        margin-bottom: 16px;
        span {
            font-size: 12px;
            font-weight: 600;
            color: #333333;
            margin: 0 16px;
        }
        .search-one {
            padding: 10px 0 12px 0;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            .left {
                button {
                    background: #2370eb;
                    border-radius: 2px;

                    margin-right: 16px;
                }
            }
        }
        .search-two {
            padding-bottom: 10px;
        }
    }
    .table-title {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        background: #fff;
        padding: 16px;
        font-size: 14px;
        font-weight: 400;
        color: #333333;
        .title_left_btn {
            span {
                display: inline-block;
                padding: 4px 13px;
                border-radius: 20px;
                font-size: 13px;
                color: #666666;
                border: 1px solid #f0f0f0;
                cursor: pointer;
            }
            span + span {
                margin-left: 12px;
            }
            .active {
                background: #e9f2ff;
                color: #2370eb;
            }
        }
        .add {
            border-color: #d9d9d9;
            color: #fff;
        }
        p {
            font-size: 14px;
            font-weight: 400;
            color: #333333;
            line-height: 20px;
        }

        button {
            font-size: 12px;
            padding: 0 20px 0 12px;
            height: 26px;
            background: #ffffff;
            border-radius: 2px;
            border: 1px solid #2370eb;
            cursor: pointer;
            color: #2370eb;
            margin-left: 16px;
            background: #2370eb;
            i {
                margin-right: 6px;
                font-size: 12px;
            }
        }
    }
    .page-box {
        background: #fff;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .Times {
        display: inline-block;
        width: 20px;
        line-height: 16px;
        font-size: 12px;
        font-weight: 500;
        color: #ffffff;
        background: #ff760f;
        border-radius: 63px 63px 63px 1px;
        text-align: center;
    }
    .Timess {
        background: #ff9385;
        margin-left: 3px;
    }
}
.slee {
    /deep/ .el-input__inner {
        font-weight: 600;
        color: #333;
    }
}
</style>
