import { render, staticRenderFns } from "./trialGiveUp.vue?vue&type=template&id=2f0361dc&scoped=true&"
import script from "./trialGiveUp.vue?vue&type=script&lang=js&"
export * from "./trialGiveUp.vue?vue&type=script&lang=js&"
import style0 from "./trialGiveUp.vue?vue&type=style&index=0&id=2f0361dc&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2f0361dc",
  null
  
)

export default component.exports