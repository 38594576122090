import { render, staticRenderFns } from "./trialDelay.vue?vue&type=template&id=08b93d54&scoped=true&"
import script from "./trialDelay.vue?vue&type=script&lang=js&"
export * from "./trialDelay.vue?vue&type=script&lang=js&"
import style0 from "./trialDelay.vue?vue&type=style&index=0&id=08b93d54&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "08b93d54",
  null
  
)

export default component.exports